import { Combobox } from '@headlessui/react';
import cn from 'classnames';
import isNil from 'lodash-es/isNil';
import * as React from 'react';
import Label from 'styleguide/components/Formik/Label/Label';
import { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import { IconArrowDown, IconTooltipQuestion } from 'styleguide/icons';
import { UiColor } from 'styleguide/styles/colors';
import { UiSize } from 'styleguide/styles/sizes';

import { DEFAULT_SIZE } from '../constants';

/**
 * @desc `Input`, `Select` and `Textarea` share the same styles,
 *       so this abstraction is used as a base for these components.
 */

export type AddonPlacement = 'left' | 'right' | 'topRight';

export interface BaseInputProps {
  id?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'url';
  name?: string;
  value: string | number | null | void;
  size?: UiSize;
  htmlSize?: string;
  className?: string;
  setRef?: (ref: React.Ref<React.ReactNode>) => void;
  children?: React.ReactNode;
  invalid?: boolean;
  success?: boolean;
  disabled?: boolean;
  minRows?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler;
  placeholder?: string;
  label?: string;
  helperText?: string;
  Tooltip?: React.ReactNode;
  labelClassName?: string;
  errorClass?: string;
  inPlaceError?: boolean;
  Icon?: (props: SvgProps) => JSX.Element;
  required?: boolean;
  selectIconColor?: UiColor;
  iconPlacement?: AddonPlacement;
  iconColor?: UiColor;
  iconClassName?: string;
  displayValue?: React.ComponentProps<typeof Combobox.Input>['displayValue'];
  wrapperClassName?: string;
}

export type Props = BaseInputProps & {
  component: 'input' | 'select' | 'textarea' | 'Combobox' | 'datepicker';
};

const AbstractInput = ({
  component,
  size = DEFAULT_SIZE,
  htmlSize,
  value,
  setRef,
  className,
  success,
  children,
  labelClassName,
  label,
  helperText,
  Tooltip,
  Icon = null,
  required,
  selectIconColor,
  invalid,
  iconPlacement,
  iconColor,
  iconClassName,
  wrapperClassName,
  ...otherProps
}: Props) => {
  const INPUT_SIZE_STYLE = {
    xs: `text-xs ${Icon ? `pl-8` : `pl-4`}`,
    sm: `text-sm pr-1 pb-2 ${Icon ? `pl-8` : `pl-4`}`,
    md: `text-sm py-3 pr-5 ${Icon ? `pl-8` : `pl-4`}`,
  };
  const forTextArea = component === 'textarea';
  const forSelect = component === 'select';
  const forCombobox = component === 'Combobox';
  const forDatepicker = component === 'datepicker';
  const inputStyle = `font-hvLite block bg-shades-0 rounded-lg w-full text-sm border-2 border-solid border-gray-300 
  appearance-none focus:outline-none text-default focus:ring-0 disabled:bg-gray-light 
  focus:border-blue peer`;

  return (
    <div className={wrapperClassName}>
      <div className="relative w-full">
        {forCombobox ? (
          <div className="rounded-lg border-2 border-solid border-gray-300">
            <Combobox.Input
              placeholder=" "
              className={cn(inputStyle, INPUT_SIZE_STYLE[size], 'truncate', className, '!border-none')}
              onChange={otherProps.onChange}
              {...otherProps}
            />
            {children}
          </div>
        ) : (
          <>
            <div
              className={`absolute flex ${
                forTextArea ? `top-[20px]` : `top-1/2`
              }  right-3 z-[5] -translate-y-1/2`}
            >
              {Tooltip && Tooltip}
            </div>
            {React.createElement(
              component === 'datepicker' ? 'input' : component,
              {
                ref: setRef,
                value: isNil(value) ? '' : value,
                size: htmlSize,
                placeholder: ' ',
                className: cn(
                  inputStyle,
                  INPUT_SIZE_STYLE[size],
                  invalid ? '!border-solid !border-2 border-red-700 !text-red-700' : '',
                  success ? '!border-solid !border-2 border-blue' : '',
                  className,
                ),
                ...otherProps,
              },
              children,
            )}
          </>
        )}
        {label && (
          <Label
            placement="float"
            invalid={invalid}
            className={labelClassName}
            forTextArea={forTextArea}
            required={required}
            inputWithIcon={!!Icon}
          >
            {label}
          </Label>
        )}
        <div
          className={cn(
            `pointer-events-none absolute flex ${forTextArea ? `top-[20px]` : `top-1/2`} -translate-y-1/2`,
            iconPlacement === 'right' ? 'right-3' : 'pl-3',
            iconPlacement === 'topRight' ? '!top-[35%] right-3' : '',
          )}
        >
          {Icon && <Icon className={iconClassName} color={invalid ? 'red' : iconColor} size={size} />}
        </div>
        {(forSelect || forCombobox || forDatepicker) && (
          <IconArrowDown
            className="pointer-events-none absolute right-3 top-1/2 flex !h-2 !w-2 -translate-y-1/2 cursor-pointer text-default"
            aria-hidden="true"
            color={selectIconColor}
          />
        )}
      </div>
      {!!helperText && !invalid && (
        <div className="mt-1 flex items-center">
          <IconTooltipQuestion size="xs" className="mr-1" />
          <div className="text-xs font-normal leading-4 text-gray-500">{helperText}</div>
        </div>
      )}
    </div>
  );
};

export default AbstractInput;
